// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uXL8l5wxVB9mG6H9tPNJ{\n  --section-bar-base-bg:var(--gray-01);\n  --section-bar-muted-bg:var(--gray-02);\n  --section-bar-inherit-bg:transparent;\n}\n\n.wSnmbNAi5FvRqYMV4j0L{\n  --section-body-base-bg:var(--white);\n  --section-body-muted-bg:var(--gray-01);\n  --section-body-elevated-bg:var(--white);\n}\n\n:root[data-theme='dark'] .uXL8l5wxVB9mG6H9tPNJ{\n    --section-bar-base-bg:var(--gray-02);\n    --section-bar-muted-bg:var(--gray-01);\n    --section-bar-inherit-bg:transparent;\n  }\n\n:root[data-theme='dark'] .wSnmbNAi5FvRqYMV4j0L{\n    --section-body-base-bg:var(--gray-01);\n    --section-body-muted-bg:var(--gray-02);\n    --section-body-elevated-bg:var(--gray-02);\n  }\n\n.g_kz5yJbe4OOMDS6rUnu{\n  --current-section-bg:var(--section-bar-base-bg);\n}\n\n.oknsLfztNbI7H3PiakQX{\n  --current-section-bg:var(--section-bar-muted-bg);\n}\n\n.SinrjWnkm2Nj5HplvawK{\n  --current-section-bg:var(--section-bar-inherit-bg);\n}\n\n.k6_th9McQJW71mqT_oGo{\n  --current-section-bg:var(--section-body-base-bg);\n}\n\n.Vgg6A_MgMz7A9_VmV1cr{\n  --current-section-bg:var(--section-body-muted-bg);\n}\n\n.WM8_5LgGwzJZP3L_xqsu{\n  --current-section-bg:var(--section-body-elevated-bg);\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section-bar": "uXL8l5wxVB9mG6H9tPNJ",
	"section-body": "wSnmbNAi5FvRqYMV4j0L",
	"section-bar-base": "g_kz5yJbe4OOMDS6rUnu",
	"section-bar-muted": "oknsLfztNbI7H3PiakQX",
	"section-bar-inherit": "SinrjWnkm2Nj5HplvawK",
	"section-body-base": "k6_th9McQJW71mqT_oGo",
	"section-body-muted": "Vgg6A_MgMz7A9_VmV1cr",
	"section-body-elevated": "WM8_5LgGwzJZP3L_xqsu"
};
export default ___CSS_LOADER_EXPORT___;
