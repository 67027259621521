import { cva, type VariantProps } from 'class-variance-authority';
import React from 'react';

import { cn } from '../../util/styles';

import style from './section-variables.module.css';

const sectionBarVariants = cva(
  [style['section-bar'], 'min-h-10 border-b border-muted bg-[--current-section-bg] py-[5px] pl-4 pr-2'], // 5px to account for 1px border and keep 40px of height.
  {
    variants: {
      variant: {
        base: style['section-bar-base'],
        muted: style['section-bar-muted'],
        inherit: style['section-bar-inherit'],
      },
    },
    defaultVariants: {
      variant: 'base',
    },
  }
);
type SectionBarVariantProps = VariantProps<typeof sectionBarVariants>;

export const SectionBar = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'> & SectionBarVariantProps
>(function SectionBar({ className, variant, ...props }, ref) {
  return <div ref={ref} className={cn(sectionBarVariants({ variant: variant }), className)} {...props} />;
});
const sectionBodyVariants = cva([style['section-body'], 'bg-[--current-section-bg]'], {
  variants: {
    variant: {
      base: style['section-body-base'],
      muted: style['section-body-muted'],
      elevated: style['section-body-elevated'],
    },
  },
  defaultVariants: {
    variant: 'base',
  },
});
type SectionBodyVariantProps = VariantProps<typeof sectionBodyVariants>;

export const SectionBarGroup = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'> & { className?: 'string' }
>(function SectionBarGroup({ className, ...props }, ref) {
  return <div ref={ref} className={cn('flex items-center gap-1', className)} {...props} />;
});

export const SectionBody = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'> & SectionBodyVariantProps
>(function SectionBody({ className, variant, ...props }, ref) {
  return <div ref={ref} className={cn(sectionBodyVariants({ variant: variant }), className)} {...props} />;
});
