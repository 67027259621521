import * as DialogPrimitive from '@radix-ui/react-dialog';
import * as React from 'react';

import { cn } from '../../util/styles';
import { Button } from '../Button';

import { type RadixClassNameTypeHelper } from './temporaryRadixTypeHelper';

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

const MODAL_TRANSITION_DURATION = 'duration-150';

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  RadixClassNameTypeHelper<React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      'fixed inset-0 bg-black opacity-80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      MODAL_TRANSITION_DURATION,
      className
    )}
    {...props}
  />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  RadixClassNameTypeHelper<React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>> & { hideClose?: boolean }
>(({ className, hideClose, children, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay className="z-[--z-index-modal-mask]" />
    <div className="fixed inset-0 z-[--z-index-modal] flex w-full justify-center p-4">
      <DialogPrimitive.Content
        ref={ref}
        className={cn(
          'fixed top-[50%] translate-y-[-50%]',
          'data-[state=open]:animate-in data-[state=closed]:animate-out',
          MODAL_TRANSITION_DURATION,
          'data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-top-[48%]',
          'h-auto w-full max-w-lg gap-4 rounded border bg-base p-6 shadow',
          className
        )}
        {...props}
      >
        {children}
        {!hideClose ? (
          <DialogPrimitive.Close asChild>
            <Button
              type="axi-tertiary"
              className="absolute right-4 top-4"
              size="small"
              icon="xmark"
              aria-label="Close"
            />
          </DialogPrimitive.Close>
        ) : null}
      </DialogPrimitive.Content>
    </div>
  </DialogPortal>
));
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogContentSidebar = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  RadixClassNameTypeHelper<React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>>
>(({ className, children, ...props }, ref) => (
  <DialogPortal>
    {/* primary consumer of this is SidebarPortal.tsx, which needs to be modal=false while rc-dropdown and rc-select exist
        if modal=false, overlay doesn't render, so duct tape fixing by adding a div with the same styles as the overlay
    */}
    <div className="fixed inset-0 bg-black opacity-80 duration-slow-01 fade-in-0" />
    <DialogPrimitive.Content
      ref={ref}
      className={cn(
        'fixed bottom-2 right-2 top-2 rounded',
        'data-[state=open]:animate-in data-[state=closed]:animate-out',
        'duration-slow-01',
        'data-[state=closed]:fade-out-0 data-[state=open]:fade-in-80 data-[state=closed]:slide-out-to-right-1/3 data-[state=open]:slide-in-from-right-1/3',
        'h-auto border bg-base shadow',
        className
      )}
      {...props}
    >
      {children}
    </DialogPrimitive.Content>
  </DialogPortal>
));
DialogContentSidebar.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({ className, ...props }: RadixClassNameTypeHelper<React.HTMLAttributes<HTMLDivElement>>) => (
  <DialogTitle asChild>
    <div className={cn('flex flex-col space-y-1.5 text-center sm:text-left', className)} {...props} />
  </DialogTitle>
);
DialogHeader.displayName = 'DialogHeader';

const DialogFooter = ({ className, ...props }: RadixClassNameTypeHelper<React.HTMLAttributes<HTMLDivElement>>) => (
  <div className={cn('flex flex-row', className)} {...props} />
);
DialogFooter.displayName = 'DialogFooter';

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  RadixClassNameTypeHelper<React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn('text-lg font-semibold leading-none tracking-tight', className)}
    {...props}
  />
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  RadixClassNameTypeHelper<React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description ref={ref} className={cn('text-sm text-muted', className)} {...props} />
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogTrigger,
  DialogClose,
  DialogContent,
  DialogContentSidebar,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
};
