import React from 'react';
import { type ControllerProps, type FieldPath, type FieldValues } from 'react-hook-form';

import { FormControl, FormField } from '../primitives/Form';
import { TextArea, type TextAreaProps } from '../TextArea';

import { FormItem } from './FormItem';
import { type FormLabelVariant } from './FormLabel';

interface FormTextAreaProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<ControllerProps<TFieldValues, TName>, 'render' | 'defaultValue'>,
    Omit<TextAreaProps, 'name'> {
  helperClassName?: string;
  helperText?: string;
  itemClassName?: string;
  horizontal?: boolean;
  label?: string;
  labelVariant?: FormLabelVariant;
  placeholder?: string;
  rootClassName?: string;
  variant?: 'default' | 'terminal';
}

export function FormTextArea<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: FormTextAreaProps<TFieldValues, TName>) {
  const {
    control,
    horizontal,
    itemClassName,
    label,
    name,
    placeholder,
    required,
    rootClassName,
    helperText,
    helperClassName,
    variant,
    ...passthrough
  } = props;

  return (
    <FormField
      control={control}
      name={name}
      key={name}
      render={({ field }) => (
        <FormItem
          className={rootClassName}
          horizontal={horizontal}
          label={label}
          labelVariant={variant}
          required={required}
          itemClassName={itemClassName}
          helperText={helperText}
          helperVariant={variant}
          helperClassName={helperClassName}
        >
          <FormControl>
            <TextArea {...field} placeholder={placeholder} {...passthrough} />
          </FormControl>
        </FormItem>
      )}
    />
  );
}
